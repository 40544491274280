import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { navigationNamespace, systemUiNamespace } from '../symbols'
import type {
	NativeMobileWixCodeSdkHandlers,
	NativeMobileWixCodeSdkSiteConfig,
	NativeMobileWixCodeSdkWixCodeApi,
} from '../types'

/**
 * This is SDK Factory.
 * Expose your Velo API
 */

export function NativeMobileSdkFactory({
	handlers,
}: WixCodeApiFactoryArgs<
	NativeMobileWixCodeSdkSiteConfig,
	unknown,
	NativeMobileWixCodeSdkHandlers
>): NativeMobileWixCodeSdkWixCodeApi {
	return {
		[navigationNamespace]: {
			dismissAllModals: handlers.navigation.dismissAllModals,
			openURL: handlers.navigation.openURL,
			toScreen: handlers.navigation.toScreen,
		},
		[systemUiNamespace]: {
			showAlert: handlers.systemUi.showAlert,
		},
	}
}
